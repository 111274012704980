<template>
  <div>
    <!-- <div class="header d-flex align-items-center justify-content-between">
      <div class="header-logo">
        <router-link :to="{ name: 'Dashboard Admin' }"
          ><div style="width: 286px; height: 79px">
            <img
              v-if="logoShop"
              :src="logoShop"
              width="100%"
              height="100%"
              style="
                object-fit: contain;
                width: 100%;
                height: 100%;
                object-position: left;
              "
            /><img
              v-else
              src="@/assets/img/logo_default.png"
              width="100%"
              height="100%"
              style="
                object-fit: contain;
                width: 100%;
                height: 100%;
                object-position: left;
              "
            /></div
        ></router-link>
      </div>
      <div class="header-listOption d-flex">
        <div class="header-listOption-bar">
          <b-icon icon="list" @click="toggleSidebar"></b-icon>
        </div>
        <div class="sidebar" :class="{ active: isActive }">
          <div class="backdrop" @click="toggleSidebar" />
          <div class="sidebar-menu">
            <div class="close-sidebar-btn" @click="toggleSidebar">
              <b-icon icon="x" font-scale="1.5" class="mr-2"></b-icon>
            </div>
            <div ref="sidebarScroll" class="sidebar-scroll">
              <div class="sidebar-items">
                <a to="/" target="_blank">
                  <div class="sidebar-item" v-on:click="toggleSidebar">
                    <b-icon
                      icon="person-fill"
                      font-scale="1.5"
                      class="mr-1"
                    ></b-icon>
                    スラグ
                  </div>
                </a>
                <router-link
                  :to="{ name: 'list content' }"
                  class="d-flex flex-column"
                >
                  <div
                    class="sidebar-item d-flex align-items-center"
                    v-on:click="toggleSidebar"
                  >
                    <b-icon icon="book" font-scale="1.5" class="mr-2"></b-icon
                    >コンテンツ
                  </div>
                </router-link>
                <router-link
                  :to="{ name: 'list page' }"
                  class="d-flex flex-column"
                >
                  <div class="sidebar-item" v-on:click="toggleSidebar">
                    <b-icon icon="cart" font-scale="1.5" class="mr-2"></b-icon
                    >ページ
                  </div>
                </router-link>
                <router-link
                  :to="{ name: 'list user' }"
                  class="d-flex flex-column"
                >
                  <div
                    class="sidebar-item d-flex align-items-center"
                    v-on:click="toggleSidebar"
                  >
                    <b-icon icon="book" font-scale="1.5" class="mr-2"></b-icon
                    >ユーザー
                  </div>
                </router-link>
              </div>
            </div>
            <div class="menu-sidebar-logout flex-column">
              <b-button
                v-if="is_login == true"
                class="logout-btn"
                :disabled="isLoading"
                @click="logoutBtn()"
                >ログアウト
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div
      class="dashboard-admin-header py-2 px-3"
      style="
        background-color: #f4f3ef !important;
        box-shadow: none;
        border-bottom: 1px solid #ddd;
      "
    >
      <div class="row justify-content-between align-items-center px-4">
        <div class="col-sm-6 px-0">
          <a
            href="#"
            class="mobile-admin pr-2"
            @click="showMenu()"
            style="color: black"
            v-if="!showHambuger"
          >
            <b-icon icon="border-width" class="my-auto"></b-icon>
          </a>
          <b-icon
            icon="arrow-left"
            class="my-auto mobile-admin pr-2"
            font-scale="1.5"
            v-else
          ></b-icon>
          <router-link :to="{ name: nameURLParent.url }" class="col-auto px-0">
            <a href="" class="" style="color: black"
              >{{ nameURLParent.title }}
            </a>
          </router-link>
          <span class="col-auto px-0" v-if="nameURLChild1.title"
            >&ensp; > &ensp;</span
          >
          <router-link :to="{ name: nameURLChild1.url }" class="col-auto px-0">
            <a
              href=""
              class=""
              style="color: black"
              v-if="nameURLChild1.title !== ''"
            >
              {{ nameURLChild1.title }}
            </a>
          </router-link>
          <span class="col-auto px-0" v-if="nameURLChild2.title"
            >&ensp; > &ensp;</span
          >
          <router-link :to="{ name: nameURLChild2.url }" class="col-auto px-0">
            <a
              href=""
              class=""
              style="color: black"
              v-if="nameURLChild2.title !== ''"
            >
              {{ nameURLChild2.title }}
            </a>
          </router-link>
          <span class="col-auto px-0" v-if="showContentTitle == true"
            >&ensp; > &ensp;</span
          >
          <router-link
            :to="{
              name: nameURLContent.url,
              params: { id: nameURLContent.id },
            }"
            class="col-auto px-0"
          >
            <a
              href=""
              class=""
              style="color: black"
              v-if="nameURLContent.title !== '' && showContentTitle == true"
            >
              {{ nameURLContent.title }}
            </a>
          </router-link>
          <span class="col-auto px-0" v-if="nameURLChild3.title"
            >&ensp; > &ensp;</span
          >
          <router-link :to="{ name: nameURLChild3.url }" class="col-auto px-0">
            <a
              href=""
              class=""
              style="color: black"
              v-if="nameURLChild3.title !== ''"
            >
              {{ nameURLChild3.title }}
            </a>
          </router-link>
        </div>
        <div class="text-sm-left text-left group-button-header">
          <CButton
            :size="'lg'"
            class=""
            style="background-color: #ef8157; color: white; font-size: 0.8571em"
            v-on:click="loginUser()"
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" small></b-spinner>
            編集者権限で表示を確認する
          </CButton>
          <CButton
            v-on:click="logoutBtn()"
            style="background-color: #ef8157; color: white; font-size: 0.8571em"
            :size="'lg'"
            class="ml-2"
          >
            ログアウト
          </CButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
import { Constants } from "../../utils/constants";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Header",
  data() {
    return {
      showSetting: false,
      isActive: false,
      isLoading: false,
      dataShop: [],
      logoShop: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      is_login: localStorage.getItem(Constants.TOKEN_ADMIN) ? true : false,
      nameURLParent: {
        url: "",
        title: "",
      },
      nameURLChild1: {
        url: "",
        title: "",
      },
      nameURLChild2: {
        url: "",
        title: "",
      },
      nameURLChild3: {
        url: "",
        title: "",
      },
      nameURLContent: {
        id: "",
        url: "",
        title: "",
      },
      showContentTitle: false,
    };
  },
  async created() {
    if (
      this.$route.name === "Content Url" ||
      this.$route.name === "Content Url domain"
    ) {
      this.showContentTitle = true;
      this.nameURLContent.id = this.contentById.id;
      this.nameURLContent.title = this.contentById.title;
      this.nameURLContent.url = this.$route.params.shopId
        ? "Edit Content Change"
        : "Edit Content Change domain";
    }
    this.nameURLChild3.title = this.$route.meta.title;
    this.nameURLChild3.url = this.$route.meta.url;
    if (this.$route.meta.breadcrumb !== undefined) {
      this.nameURLParent.title = this.$route.meta.breadcrumb.parent;
      this.nameURLParent.url = this.$route.meta.breadcrumb.url;
    }
    if (this.$route.meta.breadcrumb2 !== undefined) {
      this.nameURLChild2.title = this.$route.meta.breadcrumb2.parent;
      this.nameURLChild2.url = this.$route.meta.breadcrumb2.url;
    }
    if (this.$route.meta.breadcrumb3 !== undefined) {
      this.nameURLChild1.title = this.$route.meta.breadcrumb3.parent;
      this.nameURLChild1.url = this.$route.meta.breadcrumb3.url;
    }
  },
  computed: {
    ...mapGetters(["ListSettingShop", "showHambuger", "contentById"]),
  },
  watch: {
    $route() {
      this.nameURLContent = {
        id: "",
        url: "",
        title: "",
      };
      if (
        this.$route.name === "Content Url" ||
        this.$route.name === "Content Url domain"
      ) {
        this.showContentTitle = true;
      } else {
        this.showContentTitle = false;
      }
      this.nameURLParent = {
        url: "",
        title: "",
      };
      this.nameURLChild1 = {
        url: "",
        title: "",
      };
      this.nameURLChild2 = {
        url: "",
        title: "",
      };
      this.nameURLChild3 = {
        url: "",
        title: "",
      };
      this.nameURLChild3.title = this.$route.meta.title;
      this.nameURLChild3.url = this.$route.meta.url;
      if (this.$route.meta.breadcrumb !== undefined) {
        this.nameURLParent.title = this.$route.meta.breadcrumb.parent;
        this.nameURLParent.url = this.$route.meta.breadcrumb.url;
      }
      if (this.$route.meta.breadcrumb2 !== undefined) {
        this.nameURLChild2.title = this.$route.meta.breadcrumb2.parent;
        this.nameURLChild2.url = this.$route.meta.breadcrumb2.url;
      }
      if (this.$route.meta.breadcrumb3 !== undefined) {
        this.nameURLChild1.title = this.$route.meta.breadcrumb3.parent;
        this.nameURLChild1.url = this.$route.meta.breadcrumb3.url;
      }
    },
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].logo != null) {
          this.logoShop = Constants.URL_BE + "/" + this.dataShop[0].logo;
        }
      }
    },
    contentById() {
      if (
        this.$route.name === "Content Url" ||
        this.$route.name === "Content Url domain"
      ) {
        this.nameURLContent.id = this.contentById.id;
        this.nameURLContent.title = this.contentById.title;
        this.nameURLContent.url = this.$route.params.shopId
          ? "Edit Content Change"
          : "Edit Content Change domain";
      }
    },
  },
  methods: {
    ...mapActions({
      getListSettingShopAdmin: "getListSettingShopAdmin",
      getContentById: "getContentById",
    }),
    setShowSettings() {
      this.showSetting = !this.showSetting;
    },
    logoutBtn() {
      this.isLoading = true;
      Api.adminRequestServer
        .post(`/${Urls.LOGOUT}`)
        .then((response) => {
          const { data } = response;
          const shopId = this.$route.params.shopId;
          const extConstant = this.$route.params.shopId
            ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
            : "_" + Constants.DOMAIN;
          this.isLoading = false;
          if (data.success) {
            this.$toasted.success(data.message);
            this.$store.commit("set", ["success", false]);
            this.$store.commit("set", ["message", ""]);
            localStorage.removeItem(Constants.USER_TYPE_ADMIN + extConstant);
            localStorage.removeItem(Constants.TOKEN_ADMIN + extConstant);
            localStorage.removeItem(Constants.ADMIN_USER_INFO + extConstant);
            localStorage.removeItem(Constants.BI_SHOP + extConstant);
            localStorage.removeItem(Constants.SHOP_ID);
            localStorage.removeItem(Constants.DETAIL_SHOP);
            if (this.$route.params.shopId) {
              this.$router.push({
                name: "login admin",
                params: { shopId: shopId },
              });
            } else {
              this.$router.push({
                name: "login admin domain",
              });
            }
          } else {
            this.$toasted.error(data.message);
            this.$store.commit("set", ["message", ""]);
            this.$store.commit("set", ["error", false]);
          }
        })
        .catch(() => {
          this.isLoading = false;
          const shopId = this.$route.params.shopId;
          const extConstant = this.$route.params.shopId
            ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
            : "_" + Constants.DOMAIN;
          localStorage.removeItem(Constants.USER_TYPE_ADMIN + extConstant);
          localStorage.removeItem(Constants.TOKEN_ADMIN + extConstant);
          localStorage.removeItem(Constants.ADMIN_USER_INFO + extConstant);
          localStorage.removeItem(Constants.BI_SHOP + extConstant);
          localStorage.removeItem(Constants.SHOP_ID);
          localStorage.removeItem(Constants.DETAIL_SHOP);
          if (this.$route.params.shopId) {
            this.$router.push({
              name: "login admin",
              params: { shopId: shopId },
            });
          } else {
            this.$router.push({
              name: "login admin domain",
            });
          }
        });
    },
    toggleSidebar() {
      this.isActive = !this.isActive;
      this.$emit("toggleSidebar", this.isActive);
    },
    loginUser() {
      const self = this;
      const tokenAdmin = localStorage.getItem(Constants.TOKEN_ADMIN);
      const adminUserInfo = localStorage.getItem(Constants.ADMIN_USER_INFO);
      localStorage.setItem(Constants.TOKEN_USER, tokenAdmin);
      localStorage.setItem(
        Constants.USER_TYPE_USER,
        localStorage.getItem(Constants.USER_TYPE_ADMIN)
      );
      localStorage.setItem(
        Constants.EXPIRES_AT_USER,
        localStorage.getItem(Constants.EXPIRES_AT_ADMIN)
      );
      localStorage.setItem(
        Constants.USER_ID,
        JSON.parse(adminUserInfo).user_id
      );
      localStorage.setItem(Constants.NORMAL_USER_INFO, adminUserInfo);
      const shopId = self.$route.params.shopId;
      let routeData = null;
      if (shopId) {
        routeData = self.$router.resolve({
          name: "library",
          params: { shopId: shopId },
        });
      } else {
        routeData = self.$router.resolve({
          name: "library domain",
        });
      }
      window.open(routeData.href, "_blank");
    },
    showMenu() {
      this.$store.commit("set", ["showHambuger", !this.showHambuger]);
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  background: #fff;
  height: 100px;
  padding: 20px 50px;
  @media (max-width: 768px) {
    padding: 20px;
  }
  &-logo {
    img {
      height: 50px;
    }
  }
  &-listOption {
    &-login {
      &-text {
      }
      &-btn {
      }
    }
    &-menu {
      min-width: 250px;
      max-width: 350px;
      margin-right: 50px;
      @media (max-width: 768px) {
        display: none;
      }
      &-item {
        cursor: pointer;
        font-size: 30px;
        &-cart {
          height: 30px;
          width: 30px;
          background: orangered;
          font-size: 18px;
          border-radius: 50%;
          text-align: center;
          top: 0;
          right: 0;
        }
        .text-name {
          font-size: 16px;
        }
      }
    }
    .menu-sidebar-logout {
      width: 100%;
      height: 110px;
      border-top: 1px solid #95c895;
      display: flex;
      justify-content: center;
      align-items: center;
      .logout-btn {
        padding: 10px;
        font-size: 18px;
        cursor: pointer;
        border-radius: 10px;
        color: #fff;
        &:hover {
          background-color: #7bb97b;
        }
      }
    }
    &-bar {
      font-size: 30px;
      color: #000;
      cursor: pointer;
    }
  }
  .sidebar {
    .backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translateX(100%);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(5px);
      background-color: #0000008f;
      z-index: 1031;
    }
    &-menu {
      position: fixed;
      transform: translateX(100%);
      top: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      border-radius: 50px 0 0 0;
      padding: 60px 40px 0px 40px;
      background: #e79c3a;
      transition: all 0.5s;
      z-index: 1031;
      display: flex;
      flex-flow: column;
      flex-shrink: 1;
      width: 460px;
      .close-sidebar-btn {
        cursor: pointer;
        font-size: 28px;
        color: #fff;
        position: absolute;
        top: 10px;
        right: 25px;
      }
    }

    &-scroll {
      padding-right: 40px;
      overflow: auto;
      height: 100%;
      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #95c895;
        border-radius: 6px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #fff;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #dfdfdf;
      }
      &::-webkit-scrollbar {
        width: 3px;
      }
    }
    &-items {
      width: 330px;
      padding: 15px 0;
      &:not(:first-child) {
        border-top: 1px solid #95c895;
      }

      a {
        text-decoration: none;
        cursor: pointer;
        .sidebar-item {
          padding: 15px 20px;
          font-size: 18px;
          border-radius: 10px;
          color: #fff;
          &:hover {
            background-color: #7bb97b;
          }
        }
      }
    }
    &.active {
      .sidebar-menu {
        transform: translateX(0%);
      }
      .backdrop {
        transform: translateX(0%);
      }
    }
  }
}
</style>
