<template>
  <div class="footer">
    <div class="copyright">Copyright © 2022 - All rights reserved.</div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    isActive: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.footer {
  width: calc(100%);
  height: 50px;
  background-color: #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  // right: 0;
  .copyright {
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
