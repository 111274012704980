<template>
  <div class="dashboard-admin">
    <aside id="leftsidebar" class="sidebar">
      <!-- Menu -->
      <div class="menu">
        <ul class="list">
          <li v-for="(value, index) in listMenu" :key="index">
            <div :class="{ active: returnActive(index) }">
              <p @click="openMenu(index)" class="mb-0">
                <router-link
                  :to="{ name: value.routeName }"
                  class="menu-toggle menu1 position-relative"
                >
                  <b-icon
                    v-if="value.icon"
                    :icon="value.icon"
                    class="my-auto"
                    font-scale="1.5"
                  ></b-icon>
                  <img v-else :src="value.img" alt="" class="my-auto" />
                  <span>{{ value.textParent }}</span>
                  <span
                    class="position-absolute"
                    style="right: 14px"
                    v-if="value.child && value.child.length > 0"
                  >
                    <b-icon
                      icon="dash"
                      v-if="returnDis(index) == 'unset'"
                    ></b-icon>
                    <b-icon icon="plus" v-else></b-icon>
                  </span>
                </router-link>
              </p>
              <ul
                v-if="value.child && value.child.length > 0"
                class="ml-menu ml-menu-1"
                :style="{ display: returnDis(index) }"
              >
                <li v-for="(item, ind) in value.child" :key="ind">
                  <div
                    v-if="item.parts && item.parts.length > 0"
                    class="menu-part"
                    @click="setItemMenuParent(ind)"
                    :class="{
                      activeItem: activeIndexMenu == ind && returnActive(index),
                    }"
                    :hidden="checkEmpty(item)"
                  >
                    <div class="mb-0">
                      <!-- <b-icon
                        v-if="returnDisPart(ind) == true"
                        :icon="
                          returnDisPart(ind) == true
                            ? 'caret-down-fill'
                            : 'caret-right-fill'
                        "
                        class="my-auto"
                      ></b-icon> -->
                      <img
                        v-if="item.parts.length > 0"
                        :src="returnDisPart(ind) ? folder_open : folder_lock"
                        style="width: 20px; height: 16px"
                      />
                      <!-- <font-awesome-icon
                        class="sidebar-icon"
                        :icon="['fas', 'plus']"
                      /> -->
                      <span>{{ item.text }}</span>
                      <!-- <img
                        v-if="
                          item.parts.length > 0 && returnDisPart(ind) == false
                        "
                        :src="i_folder1"
                        style="
                          width: 16px;
                          height: 16px;
                          float: right;
                          margin-right: 15%;
                        "
                      />
                      <img
                        v-if="
                          item.parts.length > 0 && returnDisPart(ind) == true
                        "
                        :src="i_folder2"
                        style="
                          width: 16px;
                          height: 16px;
                          float: right;
                          margin-right: 15%;
                        "
                      /> -->
                      <img
                        v-if="item.parts.length > 0"
                        :src="returnDisPart(ind) ? dir_open : dir_close"
                        style="
                          position: absolute;
                          right: 15px;
                          color: #555;
                          width: 16px;
                          height: 13px;
                        "
                      />
                    </div>
                    <ul
                      class="pl-2"
                      :style="{
                        display: returnDisPart(ind) ? '' : 'none',
                      }"
                    >
                      <li
                        v-for="(part, idPart) in item.parts"
                        :key="idPart"
                        @click="setItemMenuPart(ind, idPart)"
                      >
                        <div v-if="part.roleOwner == true">
                          <div
                            v-if="
                              user_type == typeOwner ||
                              user_type == typeCDEA ||
                              (part.roleAdmin == true && user_type == typeAdmin)
                            "
                          >
                            <a
                              class="mt-3"
                              v-if="part.newTab == true"
                              :href="part.route != null ? part.route : '/'"
                              target="_blank"
                              style="padding: 0 0 0 15px"
                            >
                              <img
                                :src="cicrle_chevron"
                                class="my-auto"
                                style="width: 20px; height: 20px"
                              />
                              <span
                                v-if="activeIndexMenuPart != idPart"
                                style="font-weight: normal"
                                >{{ part.text }}</span
                              >
                              <span
                                v-else
                                :class="{
                                  activePart:
                                    activeIndexMenuPart == idPart &&
                                    activeIndexMenu == ind,
                                }"
                                >{{ part.text }}</span
                              >
                            </a>
                            <router-link
                              class="mt-3 py-0"
                              style="padding-left: 15px"
                              v-else
                              :to="{ name: part.route }"
                            >
                              <img
                                :src="cicrle_chevron"
                                class="my-auto"
                                style="width: 20px; height: 20px"
                              />
                              <span
                                v-if="activeIndexMenuPart != idPart"
                                style="font-weight: normal"
                                >{{ part.text }}</span
                              >
                              <span
                                v-else
                                :class="{
                                  activePart:
                                    activeIndexMenuPart == idPart &&
                                    activeIndexMenu == ind,
                                }"
                                >{{ part.text }}</span
                              >
                            </router-link>
                          </div>
                        </div>
                        <router-link
                          :to="{ name: part.route }"
                          class="mt-3 py-0"
                          style="padding-left: 15px"
                          v-else
                        >
                          <!-- <b-icon
                            v-if="
                              activeIndexMenuPart == idPart &&
                              activeIndexMenu == ind
                            "
                            icon="arrow-right"
                            class="my-auto"
                          ></b-icon> -->
                          <img
                            :src="cicrle_chevron"
                            class="my-auto"
                            style="width: 20px; height: 20px"
                          />
                          <span
                            v-if="activeIndexMenuPart != idPart"
                            style="font-weight: normal"
                            >{{ part.text }}</span
                          >
                          <span
                            v-else
                            :class="{
                              activePart:
                                activeIndexMenuPart == idPart &&
                                activeIndexMenu == ind,
                            }"
                            >{{ part.text }}</span
                          >
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div
                    :class="{
                      activeItem: activeIndexMenu == ind && returnActive(index),
                    }"
                    @click="setItemMenu(ind)"
                    class="menu-none-part"
                    v-else
                  >
                    <div v-if="item.roleOwner == true">
                      <div
                        v-if="user_type == typeOwner || user_type == typeCDEA"
                      >
                        <a
                          v-if="item.newTab == true"
                          :href="item.route != null ? item.route : '/'"
                          target="_blank"
                        >
                          <b-icon
                            v-if="activeIndexMenu == ind && returnActive(index)"
                            icon="caret-right-fill"
                            class="my-auto"
                          ></b-icon>
                          <span
                            :style="
                              activeIndexMenu == ind && returnActive(index)
                                ? 'margin-left: 15px'
                                : 'margin-left: 26px'
                            "
                            >{{ item.text }}</span
                          >
                        </a>
                        <router-link v-else :to="{ name: item.route }">
                          <b-icon
                            v-if="activeIndexMenu == ind && returnActive(index)"
                            icon="caret-right-fill"
                            class="my-auto"
                          ></b-icon>
                          <span
                            :style="
                              activeIndexMenu == ind && returnActive(index)
                                ? 'margin-left: 15px'
                                : 'margin-left: 26px'
                            "
                            >{{ item.text }}</span
                          >
                        </router-link>
                      </div>
                    </div>
                    <div v-else>
                      <router-link :to="{ name: item.route }">
                        <b-icon
                          v-if="activeIndexMenu == ind && returnActive(index)"
                          icon="caret-right-fill"
                          class="my-auto"
                        ></b-icon>
                        <span
                          :style="
                            activeIndexMenu == ind && returnActive(index)
                              ? 'margin-left: 15px'
                              : 'margin-left: 26px'
                          "
                          >{{ item.text }}</span
                        >
                      </router-link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <!-- #Menu -->
    </aside>
  </div>
</template>
<script>
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
import { Constants } from "../../utils/constants";
import { mapGetters } from "vuex";
// import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import dashboardCreate from "@/assets/img/dashboard-create.png";
import dashboardEdit from "@/assets/img/dashboard-edit.png";
import dashboardBox from "@/assets/img/dashboard-box.png";
import dashboardSetting from "@/assets/img/dashboard-setting.png";
import folder_lock from "@/assets/img/folder-lock.png";
import folder_open from "@/assets/img/folder_open.png";
import cicrle_chevron from "@/assets/img/circled-chevron-right_hires.png";
import dir_open from "@/assets/img/dir_open.png";
import dir_closed from "@/assets/img/dir_closed.png";

export default {
  name: "Leftbar",
  data() {
    return {
      isLoading: false,
      detailNew: null,
      indexSelect: null,
      page: 1,
      direct: null,
      callFirstTime: false,
      user_type: localStorage.getItem(
        Constants.USER_TYPE_ADMIN +
          (this.$route.params.shopId
            ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
            : "_" + Constants.DOMAIN)
      ),
      typeOwner: Constants.USER_TYPE_DEFAULT["owner"],
      typeCDEA: Constants.USER_TYPE_DEFAULT["cdea"],
      folder_lock: folder_lock,
      folder_open: folder_open,
      dir_open: dir_open,
      dir_close: dir_closed,
      cicrle_chevron: cicrle_chevron,
      showMenuPart: false,
      listMenu: [
        {
          textParent: "ホーム",
          routeName: "Dashboard Admin",
          icon: "house-fill",
          child: [],
        },
        {
          textParent: "作成メニュー",
          routeName: "DashboardAdminCreate",
          img: dashboardCreate,
          child: [
            { text: "コンテンツ作成", route: "create page" },
            { text: "コンテンツ公開設定", route: "Content Change" },
            { text: "単発ページ", route: "createFixedPage" },
            {
              text: "オファー作成",
              route: "Content Sale Create",
            },
          ],
        },
        {
          textParent: "編集メニュー",
          routeName: "DashboardAdminEdit",
          img: dashboardEdit,
          child: [
            { text: "作成済みコンテンツ一覧", route: "list page" },
            { text: "公開設定一覧", route: "list content" },
            { text: "カテゴリ管理", route: "List Category" },
            { text: "単発ページ管理", route: "listFixedPage" },
            // { text: "オファー管理", route: "list sale" },
          ],
        },
        {
          textParent: "ツールBOX",
          routeName: "DashboardAdminBox",
          img: dashboardBox,
          child: [
            {
              text: "売上管理",
              route: "",
              parts: [
                { text: "注文管理", route: "ListOrder" },
                { text: "売上管理", route: "Menu SaleManagerment" },
              ],
            },
            {
              text: "URL",
              route: "",
              parts: [
                { text: "URL作成", route: "CreateURL" },
                { text: "URL設計管理", route: "URLManager" },
              ],
            },
            {
              text: "ユーザー管理",
              route: "",
              parts: [
                { text: "ユーザー管理", route: "list user" },
                { text: "問合せ管理", route: "list inquiry" },
                { text: "ノート管理", route: "List Note" },
                { text: "質問管理", route: "Chat Admin" },
                { text: "カート利用中のユーザー一覧", route: "List Cart" },
              ],
            },
            {
              text: "メール",
              route: "",
              parts: [
                { text: "登録メルマガ管理", route: "List Mail Mega" },
                {
                  text: "簡易メール配信",
                  route: "Timing Mail",
                  roleOwner: true,
                },
              ],
            },
            {
              text: "ニュース",
              route: "",
              parts: [
                { text: "ニュース管理", route: "list new admin" },
                { text: "ニュースカテゴリ管理", route: "list category new" },
              ],
            },
            {
              text: "スラグ",
              route: "",
              parts: [{ text: "スラグ管理", route: "list slag" }],
            },
            {
              text: "データ",
              route: "",
              parts: [
                {
                  text: "アクセス中のユーザー確認",
                  route: "list realtime activity",
                },
              ],
            },
            {
              text: "著者管理",
              route: "",
              parts: [{ text: "著者管理", route: "ListAuthor" }],
            },
            {
              text: "メニュー",
              route: "",
              parts: [
                { text: "メニュー設定", route: "menuManager", roleOwner: true },
              ],
            },
            {
              text: "その他機能",
              route: "",
              parts: [
                { text: "文字置き換えシステム", route: "List sentence" },
                { text: "マイページ作成", route: "Create My Profile" },
              ],
            },
            // { text: "ポイント設定", route: "list point" },
          ],
        },
        {
          textParent: "全体設定",
          routeName: "DashboardAdminSetting",
          img: dashboardSetting,
          child: [
            { text: "一般設定", route: "General Setting", roleOwner: true },
            { text: "SNS設定", route: "SNS Setting", roleOwner: true },
            { text: "決済情報登録", route: "paymentSetting", roleOwner: true },
            { text: "販売情報設定", route: "Sales Setting", roleOwner: true },
            { text: "ストア設定", route: "store setting", roleOwner: true },
            { text: "メール設定", route: "email setting" },
            { text: "デザイン設定", route: "design setting" },
          ],
        },
        // {
        //   textParent: "他のメニュー",
        //   routeName: "DashboardAdminOther",
        //   img: iconDefault,
        //   child: [
        //     { text: "お問い合わせ一覧", route: "list inquiry" },
        //     { text: "ページ管理", route: "list page" },
        //     { text: "トリガー管理", route: "List Trigger" },
        //     { text: "ニュースカテゴリ管理", route: "list category new" },
        //     { text: "アンケート管理", route: "list survey" },
        //   ],
        // },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "listContent",
      "message",
      "success",
      "error",
      "isActive0",
      "isActive1",
      "isActive2",
      "isActive3",
      "isActive4",
      "isActive5",
      "dis1",
      "dis2",
      "dis3",
      "dis4",
      "dis5",
      "activeIndexMenu",
      "activeIndexMenuPart",
      "getShopById",
    ]),
  },
  async created() {
    let shopId = this.$route.params.shopId;
    if (!shopId) {
      this.listMenu.forEach((obj) => {
        obj.routeName += " domain";
        let child = obj.child;
        if (child) {
          child.forEach((childElement) => {
            if (childElement.route.trim() !== "") {
              if (
                childElement.route != null
              ) {
                childElement.route += " domain";
              }
            }
            let parts = childElement.parts;
            if (parts) {
              parts.forEach((part) => {
                if (part.route != null) {
                  part.route += " domain";
                }
              });
            }
          });
        }
      });
    }
    // let request = {
    //   domain: Constants.DOMAIN,
    //   shop_id: this.$route.params.shopId || null,
    // };
    // await this.$store.dispatch("getShopById", request);
    if (this.$route.fullPath.includes("dashboard/create")) {
      this.$store.commit("set", ["isActive1", true]);
      this.$store.commit("set", ["isActive0", false]);
    } else if (this.$route.fullPath.includes("dashboard/edit")) {
      this.$store.commit("set", ["isActive2", true]);
      this.$store.commit("set", ["isActive0", false]);
    } else if (this.$route.fullPath.includes("dashboard/box")) {
      this.$store.commit("set", ["isActive3", true]);
      this.$store.commit("set", ["isActive0", false]);
    } else if (this.$route.fullPath.includes("dashboard/setting")) {
      this.$store.commit("set", ["isActive4", true]);
      this.$store.commit("set", ["isActive0", false]);
    } else if (this.$route.fullPath.includes("dashboard/other")) {
      this.$store.commit("set", ["isActive5", true]);
      this.$store.commit("set", ["isActive0", false]);
    } else if (this.$route.fullPath.includes("dashboard")) {
      this.$store.commit("set", ["isActive0", true]);
    }
  },
  watch: {
    $route() {
      if (this.$route.fullPath.includes("dashboard/create")) {
        this.$store.commit("set", ["isActive1", true]);
        this.$store.commit("set", ["isActive0", false]);
      } else if (this.$route.fullPath.includes("dashboard/edit")) {
        this.$store.commit("set", ["isActive2", true]);
        this.$store.commit("set", ["isActive0", false]);
      } else if (this.$route.fullPath.includes("dashboard/box")) {
        this.$store.commit("set", ["isActive3", true]);
        this.$store.commit("set", ["isActive0", false]);
      } else if (this.$route.fullPath.includes("dashboard/setting")) {
        this.$store.commit("set", ["isActive4", true]);
        this.$store.commit("set", ["isActive0", false]);
      } else if (this.$route.fullPath.includes("dashboard/other")) {
        this.$store.commit("set", ["isActive5", true]);
        this.$store.commit("set", ["isActive0", false]);
      } else if (this.$route.fullPath.includes("dashboard")) {
        this.$store.commit("set", ["isActive0", true]);
      }
    },
    page() {
      this.getListNews(this.page);
    },
    listNewAdminDashboard() {
      if (this.listNewAdminDashboard.data.length && this.callFirstTime) {
        this.indexSelect =
          this.direct === "next"
            ? 0
            : this.listNewAdminDashboard.data.length - 1;
        this.detailNew = this.listNewAdminDashboard.data.filter(
          (item, index) => index === this.indexSelect
        )[0];
      } else {
        this.callFirstTime = true;
      }
    },
  },
  methods: {
    // ...mapActions({ getShopById: "getShopById" }),
    moment,
    logoutBtn() {
      this.isLoading = true;
      Api.adminRequestServer
        .post(`/${Urls.LOGOUT}`)
        .then((response) => {
          const { data } = response;
          const shopId = this.$route.params.shopId;
          this.isLoading = false;
          if (data.success) {
            this.$toasted.success(data.message);
            this.$store.commit("set", ["success", false]);
            this.$store.commit("set", ["message", ""]);
            localStorage.removeItem(
              Constants.USER_TYPE_ADMIN +
                (this.$route.params.shopId
                  ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                  : "_" + Constants.DOMAIN)
            );
            localStorage.removeItem(
              Constants.TOKEN_ADMIN +
                (this.$route.params.shopId
                  ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                  : "_" + Constants.DOMAIN)
            );
            localStorage.removeItem(
              Constants.ADMIN_USER_INFO +
                (this.$route.params.shopId
                  ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                  : "_" + Constants.DOMAIN)
            );
            if (shopId) {
              this.$router.push({
                name: "login admin",
                params: { shopId: shopId },
              });
            } else {
              this.$router.push({
                name: "login admin domain",
              });
            }
          } else {
            this.$toasted.error(data.message);
            this.$store.commit("set", ["message", ""]);
            this.$store.commit("set", ["error", false]);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toasted.error(error.message);
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
        });
    },
    loginUser() {
      this.isLoading = true;
      const self = this;
      if (localStorage.getItem(Constants.TOKEN_USER)) {
        Api.userRequestServer
          .post(Urls.LOGOUT)
          .then((response) => {
            const { data } = response;
            if (data.success) {
              this.$store.commit("set", ["success", false]);
              this.$store.commit("set", ["message", ""]);
              localStorage.removeItem(Constants.USER_TYPE_USER);
              localStorage.removeItem(Constants.TOKEN_USER);
              localStorage.removeItem(Constants.NORMAL_USER_INFO);
              localStorage.removeItem(Constants.LIST_SLAG);
            } else {
              this.$toasted.error(data.message);
              this.$store.commit("set", ["message", ""]);
              this.$store.commit("set", ["error", false]);
            }
          })
          .catch((error) => {
            this.$toasted.error(error.message);
            this.$store.commit("set", ["message", ""]);
            this.$store.commit("set", ["error", false]);
          });
      }
      setTimeout(function () {
        self.isLoading = false;
        const shopId = this.$route.params.shopId;
        if (shopId) {
          self.$router.push({
            name: "library",
            params: { shopId: shopId },
          });
        } else {
          self.$router.push({
            name: "library domain",
          });
        }
      }, 2000);
    },
    openMenu(index) {
      //   this["dis" + index] = this["dis" + index] == "none" ? "unset" : "none";
      for (var j = 0; j < Constants.COUNT_DASHBOARD; j++) {
        var ind = "isActive" + j;
        var dis = "dis" + j;
        if (j != index) {
          this.$store.commit("set", [dis, "none"]);
          this.$store.commit("set", [ind, false]);
        } else {
          if (this[dis] == "unset") {
            this.$store.commit("set", [dis, "none"]);
          } else {
            this.$store.commit("set", [dis, "unset"]);
            this.$store.commit("set", [ind, true]);
          }
        }
      }
      this.$store.commit("set", ["activeIndexMenu", -1]);
      this.$store.commit("set", ["activeIndexMenuPart", -1]);
      localStorage.removeItem(Constants.PAGE_PREVIEW);
      localStorage.removeItem(Constants.NO_FIXED_PAGE);
      localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
      this.$store.commit("set", ["checkHeaderImg", ""]);
      this.$store.commit("set", ["checkBackgroundImg", ""]);
      this.$store.commit("set", ["checkArticleImg", ""]);
    },
    setItemMenu(index) {
      this.$store.commit("set", ["activeIndexMenu", index]);
      this.$store.commit("set", ["activeIndexMenuPart", -1]);
      localStorage.removeItem(Constants.PAGE_PREVIEW);
      localStorage.removeItem(Constants.NO_FIXED_PAGE);
      localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
      this.$store.commit("set", ["checkHeaderImg", ""]);
      this.$store.commit("set", ["checkBackgroundImg", ""]);
      this.$store.commit("set", ["checkArticleImg", ""]);
    },
    returnActive(index) {
      return this["isActive" + index];
    },
    returnDis(index) {
      return this["dis" + index];
    },
    setItemMenuPart(ind, idPart) {
      this.showMenuPart = true;
      this.$store.commit("set", ["activeIndexMenu", ind]);
      this.$store.commit("set", ["activeIndexMenuPart", idPart]);
      localStorage.removeItem(Constants.PAGE_PREVIEW);
      localStorage.removeItem(Constants.NO_FIXED_PAGE);
      localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
      this.$store.commit("set", ["checkHeaderImg", ""]);
      this.$store.commit("set", ["checkBackgroundImg", ""]);
      this.$store.commit("set", ["checkArticleImg", ""]);
    },
    setItemMenuParent(index) {
      var check = this.activeIndexMenu;
      this.$store.commit("set", ["activeIndexMenu", index]);
      if (check != index) {
        this.$store.commit("set", ["activeIndexMenuPart", -1]);
        this.showMenuPart = true;
      }
      localStorage.removeItem(Constants.PAGE_PREVIEW);
      localStorage.removeItem(Constants.NO_FIXED_PAGE);
      localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
      this.$store.commit("set", ["checkHeaderImg", ""]);
      this.$store.commit("set", ["checkBackgroundImg", ""]);
      this.$store.commit("set", ["checkArticleImg", ""]);
    },
    returnDisPart(index) {
      return this.activeIndexMenu == index ? true : false;
    },
    checkEmpty(part) {
      let array = [];
      part.parts.forEach((item) => {
        if (item.roleOwner && item.roleOwner == true) {
          if (
            this.user_type == this.typeOwner ||
            this.user_type == this.typeCDEA ||
            (item.roleAdmin == true && this.user_type == this.typeAdmin)
          ) {
            array.push(item);
          }
        } else {
          array.push(item);
        }
      });

      if (array.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
